import React from 'react'
import Image from 'next/image'

import styles from './Footer.module.css'

const Footer = () => {
  return (
    <footer className="bg-bg-3 pt-8 pb-12 mt-10">
      <div className="container">
        <div className="row container">
          <div className="mb-md-0 mb-12 col-10 col-sm-6 col-lg-3 col-md-4">
            <h2 className={styles.tuefectivo}>tuefectivo.uy</h2>
            <p className={`fs-5 mt-3 ${styles.holaText}`}>hola@tuefectivo.uy</p>
          </div>
          <div
            className="
              mb-md-0
              col-sm-6
              mb-sm-8 mb-6
              col-8 col-lg-3 col-xl col-md-4
            "
          >
            <h5 className={`mb-4 ${styles.boldFooter}`}>Sobre nosotros</h5>
            <a
              href="https://tuefectivo.uy/#como-funciona"
              className={`mb-4 me-2 me-sm-0 d-sm-block text-decoration-none ${styles.text_dark_1}`}
            >
              ¿Cómo funciona?
            </a>
            <a
              href="mailto:hola@tuefectivo.uy?subject=Contacto desde app.tuefectivo.uy"
              className={`mb-4 me-2 me-sm-0 d-sm-block text-decoration-none ${styles.text_dark_1}`}
            >
              Contáctanos
            </a>
          </div>
          <div className="col-sm-6 mb-6 col-10 col-lg-3 col-xl col-md-4">
            <h5 className={`mb-4 ${styles.boldFooter}`}>Vea con atención</h5>
            <a
              href="https://tuefectivo.uy/terminos-y-condiciones"
              className={`mb-4 me-2 me-sm-0 d-sm-block  text-decoration-none ${styles.text_dark_1}`}
            >
              Términos y condiciones
            </a>
            <a
              href="https://tuefectivo.uy/politica-de-cookies"
              className={`mb-4 me-2 me-sm-0 d-sm-block text-decoration-none ${styles.text_dark_1}`}
            >
              Política de cookies
            </a>
          </div>
          <div className="col-sm-6 mb-6 col-10 col-lg-3 col-xl col-md-4">
            <h5 className={`mb-4 ${styles.boldFooter}`}>
              Nuestras redes sociales
            </h5>
            <a
              href="https://www.facebook.com/tuefectivouy"
              target="_blank"
              className="me-4 text-decoration-none"
              rel="noreferrer"
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={`${styles.fill_dark_1}`}
              >
                <path d="M12 2.03906C6.5 2.03906 2 6.52906 2 12.0591C2 17.0591 5.66 21.2091 10.44 21.9591V14.9591H7.9V12.0591H10.44V9.84906C10.44 7.33906 11.93 5.95906 14.22 5.95906C15.31 5.95906 16.45 6.14906 16.45 6.14906V8.61906H15.19C13.95 8.61906 13.56 9.38906 13.56 10.1791V12.0591H16.34L15.89 14.9591H13.56V21.9591C15.9164 21.5869 18.0622 20.3846 19.6099 18.5691C21.1576 16.7537 22.0054 14.4447 22 12.0591C22 6.52906 17.5 2.03906 12 2.03906Z"></path>
              </svg>
            </a>
            <a
              href="https://www.instagram.com/tuefectivouy"
              target="_blank"
              className="m-2 text-decoration-none"
              rel="noreferrer"
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={`${styles.fill_dark_1}`}
              >
                <path d="M7.8 2H16.2C19.4 2 22 4.6 22 7.8V16.2C22 17.7383 21.3889 19.2135 20.3012 20.3012C19.2135 21.3889 17.7383 22 16.2 22H7.8C4.6 22 2 19.4 2 16.2V7.8C2 6.26174 2.61107 4.78649 3.69878 3.69878C4.78649 2.61107 6.26174 2 7.8 2ZM7.6 4C6.64522 4 5.72955 4.37928 5.05442 5.05442C4.37928 5.72955 4 6.64522 4 7.6V16.4C4 18.39 5.61 20 7.6 20H16.4C17.3548 20 18.2705 19.6207 18.9456 18.9456C19.6207 18.2705 20 17.3548 20 16.4V7.6C20 5.61 18.39 4 16.4 4H7.6ZM17.25 5.5C17.5815 5.5 17.8995 5.6317 18.1339 5.86612C18.3683 6.10054 18.5 6.41848 18.5 6.75C18.5 7.08152 18.3683 7.39946 18.1339 7.63388C17.8995 7.8683 17.5815 8 17.25 8C16.9185 8 16.6005 7.8683 16.3661 7.63388C16.1317 7.39946 16 7.08152 16 6.75C16 6.41848 16.1317 6.10054 16.3661 5.86612C16.6005 5.6317 16.9185 5.5 17.25 5.5ZM12 7C13.3261 7 14.5979 7.52678 15.5355 8.46447C16.4732 9.40215 17 10.6739 17 12C17 13.3261 16.4732 14.5979 15.5355 15.5355C14.5979 16.4732 13.3261 17 12 17C10.6739 17 9.40215 16.4732 8.46447 15.5355C7.52678 14.5979 7 13.3261 7 12C7 10.6739 7.52678 9.40215 8.46447 8.46447C9.40215 7.52678 10.6739 7 12 7ZM12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9Z"></path>
              </svg>
            </a>
          </div>
        </div>
        <p className="fs-6 mb-0 text-center mt-7" style={{ opacity: '0.5' }}>
          GenLeads
        </p>
        <p className="fs-6 mb-0 text-center mt-2" style={{ opacity: '0.5' }}>
          © Hecho con ❤️ desde 🇺🇾
        </p>
        {/* <div className="row text-center justify-content-center align-items-center mt-10">
          <div className="col-md-6 col-lg-4 col-xl-4 col-xxl-4">
            <Image
              src="/i/cuti.png"
              alt="socio cuti"
              width={260}
              height={100}
            />
          </div>
        </div> */}
      </div>
    </footer>
  )
}

export default Footer
