/* eslint-disable @next/next/no-sync-scripts */
import React from 'react'
import Head from 'next/head'

const HeadData = ({ title }) => {
  return (
    <Head>
      <title>{title}</title>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="author" content="tuefectivo.uy" />
      <meta
        name="keywords"
        content="prestamo, creditos, efectivo, uruguay, rapido, facil"
      />
      <meta
        name="description"
        content="Somos una plataforma digital que ayuda a los usuarios que necesitan efectivo a conectarse con empresas financieras del mercado que estén dispuestas a prestarle. Además de brindar a nuestros usuarios un servicio 100% gratis, los ayudamos a elegir la mejor oferta brindándole diferentes opciones y las tasas más convenientes."
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="i/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="i/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="i/favicon-16x16.png"
      />
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
        integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
        crossOrigin="true"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Baloo+2:wght@100;200;300;400;500;600;700;800;900&family=Baloo+2:wght@100;200;300;400;500;600;700;800;900&subset=latin&display=swap"
        rel="stylesheet"
      />
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta2/css/all.min.css"
        integrity="sha512-YWzhKL2whUzgiheMoBFwW8CKV4qpHQAEuvilg9FAn5VJUDwKZZxkJNuGM4XkWuk94WCrrwslk8yWNGmY1EduTA=="
        crossOrigin="true"
        referrerPolicy="no-referrer"
      />
      <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.2/dist/js/bootstrap.bundle.min.js"></script>
      {typeof window !== 'undefined' && (
        <script type="text/javascript">
          {(function (w, d, s, l, i) {
            w[l] = w[l] || []
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != 'dataLayer' ? '&l=' + l : ''
            j.async = true
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
            f.parentNode.insertBefore(j, f)
          })(window, document, 'script', 'dataLayer', 'GTM-NJDVLXP')}
        </script>
      )}
    </Head>
  )
}

export default HeadData
