import React from 'react'
import Header from '@/components/Main/Header'
import Footer from '@/components/Main/Footer/Footer'
import HeadData from '@/components/Main/HeadData/HeadData'

const MainLayout = ({ children, title }) => {
  return (
    <div>
      <HeadData title={title} />
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default MainLayout
