import Link from 'next/link'
import React from 'react'
import styles from './Header.module.css'

const Header = () => {
  return (
    <nav className="bg-bg-3 sticky-top py-4 py-lg-7">
      <div className="d-block d-lg-none">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-3">
              <a
                className="
                  btn btn-width-equal-height
                  d-lg-none
                  rounded-circle
                  custom-mobile-nav-btn
                  bg-transparent
                  text-decoration-none
                "
                data-bs-toggle="offcanvas"
                data-bs-target="#custom-id-qy3e98mx"
              >
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="fill-action-2"
                >
                  <path d="M2.00016 5.33333H14.0002C14.177 5.33333 14.3465 5.2631 14.4716 5.13807C14.5966 5.01305 14.6668 4.84348 14.6668 4.66667C14.6668 4.48986 14.5966 4.32029 14.4716 4.19526C14.3465 4.07024 14.177 4 14.0002 4H2.00016C1.82335 4 1.65378 4.07024 1.52876 4.19526C1.40373 4.32029 1.3335 4.48986 1.3335 4.66667C1.3335 4.84348 1.40373 5.01305 1.52876 5.13807C1.65378 5.2631 1.82335 5.33333 2.00016 5.33333ZM14.0002 10.6667H2.00016C1.82335 10.6667 1.65378 10.7369 1.52876 10.8619C1.40373 10.987 1.3335 11.1565 1.3335 11.3333C1.3335 11.5101 1.40373 11.6797 1.52876 11.8047C1.65378 11.9298 1.82335 12 2.00016 12H14.0002C14.177 12 14.3465 11.9298 14.4716 11.8047C14.5966 11.6797 14.6668 11.5101 14.6668 11.3333C14.6668 11.1565 14.5966 10.987 14.4716 10.8619C14.3465 10.7369 14.177 10.6667 14.0002 10.6667ZM14.0002 7.33333H2.00016C1.82335 7.33333 1.65378 7.40357 1.52876 7.5286C1.40373 7.65362 1.3335 7.82319 1.3335 8C1.3335 8.17681 1.40373 8.34638 1.52876 8.4714C1.65378 8.59643 1.82335 8.66667 2.00016 8.66667H14.0002C14.177 8.66667 14.3465 8.59643 14.4716 8.4714C14.5966 8.34638 14.6668 8.17681 14.6668 8C14.6668 7.82319 14.5966 7.65362 14.4716 7.5286C14.3465 7.40357 14.177 7.33333 14.0002 7.33333Z"></path>
                </svg>
              </a>
            </div>
            <div className="col-6 text-center">
              <Link href="/" className="text-decoration-none">
                <a style={{ textDecoration: 'none', fontWeight: '700' }}>
                  <h1
                    style={{
                      fontWeight: '700 !important',
                      letterSpacing: '-0.04em !important',
                      fontFamily: 'Baloo 2',
                    }}
                    className={styles.headFontWeight}
                  >
                    tuefectivo.uy
                  </h1>
                </a>
              </Link>
            </div>
            <div className="col-3"></div>
          </div>
        </div>
      </div>
      <div
        className="offcanvas offcanvas-start bg-bg-2"
        id="custom-id-qy3e98mx"
        aria-hidden="true"
      >
        <div className="offcanvas-header mt-3 ml-1 d-flex align-items-center justify-content-between">
          <h1 className="text-light-1 " style={{ fontWeight: '550' }}>
            tuefectivo.uy
          </h1>

          <a
            href="#"
            className="btn btn-sm btn-width-equal-height btn-bg-3 mr-2 mb-1 text-decoration-none"
            data-bs-dismiss="offcanvas"
            style={{
              borderRadius: '5rem',
              backgroundColor: '#fff',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="fill-dark-2"
            >
              <path
                d="M8.9398 8L13.1398 3.80667C13.2653 3.68113 13.3359 3.51087 13.3359 3.33333C13.3359 3.1558 13.2653 2.98554 13.1398 2.86C13.0143 2.73447 12.844 2.66394 12.6665 2.66394C12.4889 2.66394 12.3187 2.73447 12.1931 2.86L7.9998 7.06L3.80646 2.86C3.68093 2.73447 3.51066 2.66394 3.33313 2.66394C3.1556 2.66394 2.98533 2.73447 2.8598 2.86C2.73426 2.98554 2.66374 3.1558 2.66374 3.33333C2.66374 3.51087 2.73426 3.68113 2.8598 3.80667L7.0598 8L2.8598 12.1933C2.79731 12.2553 2.74771 12.329 2.71387 12.4103C2.68002 12.4915 2.6626 12.5787 2.6626 12.6667C2.6626 12.7547 2.68002 12.8418 2.71387 12.9231C2.74771 13.0043 2.79731 13.078 2.8598 13.14C2.92177 13.2025 2.99551 13.2521 3.07675 13.2859C3.15798 13.3198 3.24512 13.3372 3.33313 13.3372C3.42114 13.3372 3.50827 13.3198 3.58951 13.2859C3.67075 13.2521 3.74449 13.2025 3.80646 13.14L7.9998 8.94L12.1931 13.14C12.2551 13.2025 12.3288 13.2521 12.4101 13.2859C12.4913 13.3198 12.5785 13.3372 12.6665 13.3372C12.7545 13.3372 12.8416 13.3198 12.9228 13.2859C13.0041 13.2521 13.0778 13.2025 13.1398 13.14C13.2023 13.078 13.2519 13.0043 13.2857 12.9231C13.3196 12.8418 13.337 12.7547 13.337 12.6667C13.337 12.5787 13.3196 12.4915 13.2857 12.4103C13.2519 12.329 13.2023 12.2553 13.1398 12.1933L8.9398 8Z"
                fill="#0C111E"
              ></path>
            </svg>
          </a>
        </div>
        <div className="offcanvas-body">
          <div>
            <a
              href="https://tuefectivo.uy/#como-funciona"
              className={`fw-bold py-2 d-inline-block text-light-1 fs-5 text-decoration-none ${styles.sideBarText} `}
              style={{ textDecoration: 'none' }}
            >
              ¿Cómo funciona?
            </a>
          </div>
          <div>
            <a
              href="mailto:hola@tuefectivo.uy?subject=Contacto desde app.tuefectivo.uy"
              className={`fw-bold py-2 d-inline-block text-light-1 fs-5 ${styles.sideBarText} `}
            >
              Contáctanos
            </a>
          </div>
          <div className="mt-10">
            <a
              href="https://tuefectivo.uy/terminos-y-condiciones"
              className="py-2 d-inline-block text-light-1 fw-light text-decoration-none "
              style={{ fontSize: '0.75rem !important' }}
            >
              Términos y condiciones
            </a>
          </div>
          <div>
            <a
              href="https://tuefectivo.uy/politica-de-cookies"
              className="py-2 d-inline-block text-light-1  fw-light text-decoration-none  "
              style={{ fontSize: '0.75rem !important' }}
            >
              Política de cookies
            </a>
          </div>
          <div className="mt-9">
            <a
              href="https://www.facebook.com/tuefectivouy"
              target="_blank"
              className="me-4"
              rel="noreferrer"
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="fill-light-1"
              >
                <path d="M12 2.03906C6.5 2.03906 2 6.52906 2 12.0591C2 17.0591 5.66 21.2091 10.44 21.9591V14.9591H7.9V12.0591H10.44V9.84906C10.44 7.33906 11.93 5.95906 14.22 5.95906C15.31 5.95906 16.45 6.14906 16.45 6.14906V8.61906H15.19C13.95 8.61906 13.56 9.38906 13.56 10.1791V12.0591H16.34L15.89 14.9591H13.56V21.9591C15.9164 21.5869 18.0622 20.3846 19.6099 18.5691C21.1576 16.7537 22.0054 14.4447 22 12.0591C22 6.52906 17.5 2.03906 12 2.03906Z"></path>
              </svg>
            </a>
            <a
              href="https://www.instagram.com/tuefectivouy"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="fill-dark-1 fill-light-1"
              >
                <path d="M7.8 2H16.2C19.4 2 22 4.6 22 7.8V16.2C22 17.7383 21.3889 19.2135 20.3012 20.3012C19.2135 21.3889 17.7383 22 16.2 22H7.8C4.6 22 2 19.4 2 16.2V7.8C2 6.26174 2.61107 4.78649 3.69878 3.69878C4.78649 2.61107 6.26174 2 7.8 2ZM7.6 4C6.64522 4 5.72955 4.37928 5.05442 5.05442C4.37928 5.72955 4 6.64522 4 7.6V16.4C4 18.39 5.61 20 7.6 20H16.4C17.3548 20 18.2705 19.6207 18.9456 18.9456C19.6207 18.2705 20 17.3548 20 16.4V7.6C20 5.61 18.39 4 16.4 4H7.6ZM17.25 5.5C17.5815 5.5 17.8995 5.6317 18.1339 5.86612C18.3683 6.10054 18.5 6.41848 18.5 6.75C18.5 7.08152 18.3683 7.39946 18.1339 7.63388C17.8995 7.8683 17.5815 8 17.25 8C16.9185 8 16.6005 7.8683 16.3661 7.63388C16.1317 7.39946 16 7.08152 16 6.75C16 6.41848 16.1317 6.10054 16.3661 5.86612C16.6005 5.6317 16.9185 5.5 17.25 5.5ZM12 7C13.3261 7 14.5979 7.52678 15.5355 8.46447C16.4732 9.40215 17 10.6739 17 12C17 13.3261 16.4732 14.5979 15.5355 15.5355C14.5979 16.4732 13.3261 17 12 17C10.6739 17 9.40215 16.4732 8.46447 15.5355C7.52678 14.5979 7 13.3261 7 12C7 10.6739 7.52678 9.40215 8.46447 8.46447C9.40215 7.52678 10.6739 7 12 7ZM12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9Z"></path>
              </svg>
            </a>
          </div>
          <div className="mt-10">
            <p className="text-light-1" style={{ fontSize: '0.75rem' }}>
              © Hecho con ❤️ desde 🇺🇾
            </p>
          </div>
        </div>
      </div>
      <div className="d-none d-lg-block">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-2">
              <Link href="/">
                <a className="text-decoration-none">
                  <h1
                    style={{
                      fontWeight: '700 !important',
                      letterSpacing: '-0.04em !important',
                      fontFamily: 'Baloo 2',
                    }}
                    className={styles.headFontWeight}
                  >
                    tuefectivo.uy
                  </h1>
                </a>
              </Link>
            </div>
            <div className="col-8 d-flex justify-content-end">
              <a
                href="https://tuefectivo.uy/#como-funciona"
                className="fw-bold text-dark-1 ms-4 text-decoration-none"
              >
                ¿Cómo funciona?
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Header
